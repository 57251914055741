import { createStore } from 'redux'
import query from './api'

const initialState = {
  sidebarShow: false,
  record: {
    key: '',
    name: '',
    business: '',
    email: '',
    notes: '',
    type: '',
    expiration: '',
    tunnel: {
      id: '',
      account: '',
      subdomain: ''
    }
  },
  records: {
    active: [],
    expired: []
  },
  recordsPending : [],
  interval: null
}

const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'set':
      return { ...state, ...rest }
    case 'save':
      query('save', state.record)
      return { ...state, record: initialState.record }
    case 'reset':
      return { ...state, record: initialState.record }
    case 'delete':
      query('delete', state.record)
      return { ...state, record: initialState.record }
    default:
      return { ...state, records: query('get') }
  }
}

const store = createStore(changeState)

export default store
