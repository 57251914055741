const axios = require('axios');

function query(type, data = {}) {
  switch (type) {
    case "get":
      return getLicenses()
    case "save":
      return saveLicense(data)
    case "delete":
      return deleteLicense(data)
    default:
      return getLicenses()
  }
}

let records = {
  active: [],
  expired: []
};

function getLicenses() {
  axios.get('/api/admin', {
    headers: {
      'Authorization': '47dsutEtrdi3utSh3f9djk32fdlSYf32',
      'Content-Type': 'application/json;charset=UTF-8',
    }
  }).then((response) => {
      // handle success
      let payload = {
        active: [],
        expired: []
      };
      response.data.licenses.forEach((license) => {
        if(license.type === 'Special' || license.type === 'Permanent' || license.expiration > new Date().toISOString().slice(0, 10)){
          payload.active.push(license)
        } else {
          payload.expired.push(license)
        }
      })
      records = payload
    }).catch(function (error) {
      // handle error
      console.log(error);
    })
  console.log(records)
  return records
}

function saveLicense(data) {

  let req = {
    type: "New",
    key: data.key,
    payload: {
      name: data.name,
      email: data.email,
      business: data.business,
      expiration: data.expiration,
      notes: data.notes,
      type: data.type,
      tunnel: data.tunnel
    }
  }

  if(data.key > '') {
    req.type = "Update"
  }

  axios.post('/api/admin', req, {
    headers: {
      'Authorization': '47dsutEtrdi3utSh3f9djk32fdlSYf32',
      'Content-Type': 'application/json;charset=UTF-8'
    }
  }).then((response) => {
    if(data.key === ''){
      alert('License Added: Please wait 60 seconds to propagate.')
    } else {
      alert('License Updated: Please wait 60 seconds to propagate.')
    }
  }).catch(function (error) {
    // handle error
    console.log(error);
  })
}

function deleteLicense(data) {

  let req = {
    type: "Delete",
    key: data.key
  }

  axios.post('/api/admin', req, {
    headers: {
      'Authorization': '47dsutEtrdi3utSh3f9djk32fdlSYf32',
      'Content-Type': 'application/json;charset=UTF-8'
    }
  }).then((response) => {
    alert('License Removed: Please wait 60 seconds to propagate.')
  }).catch(function (error) {
    // handle error
    console.log(error);
  })
}

export default query
